<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title class="my-0 py-0">
                    <div
                        class="d-flex justify-space-between flex-wrap"
                        style="width: 100%"
                    >
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createPlayer"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ 'Agregar Jugador'/*$t('admin.posts.add')*/ }}
                        </v-btn>

                        <v-btn
                            class="ma-2"
                            dark
                            color="warning"
                            @click="dialogAuto = true"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ 'Crear X Jugadores Automaticamente'/*$t('admin.posts.add')*/ }}
                        </v-btn>

                        <v-btn
                            class="ma-2"
                            dark
                            color="danger"
                            @click="deletePlayers"
                        >
                            <v-icon>mdi-delete</v-icon>
                            {{ 'Eliminar Jugadores'/*$t('admin.posts.add')*/ }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ 'Jugadores'/*$t('admin.posts.title')*/ }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <!--                <role-create
            v-if="dialog"
            :dialog="dialog"
            :item="itemSelected"
            @update:dialog="dialog = $event"
            @update:table="fetchGames"
        />-->
                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.pivot.team="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.username="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.lastname="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.email="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.enable="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.pivot.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <!--                    <template v-slot:item.role_id="{ item }">
    {{ itemsRoles.find((r) => r.id_role == item.role_id).name }}
</template>-->

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deletePlayers(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchPlayers"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <player-create
                v-if="dialog"
                :dialog="dialog"
                :item="itemSelected"
                :teams_count="teams_count"
                :game-id="gameId"
                @update:dialog="dialog = $event"
                @update:table="fetchPlayers"
            />

            <player-create-automatically
                v-if="dialogAuto"
                :dialog="dialogAuto"
                :teams_count="teams_count"
                :game-id="gameId"
                @update:dialog="dialogAuto = $event"
                @update:table="fetchPlayers"
            />

            <confirm-dialog
                ref="confirm"
            />

            <option-select-dialog
                ref="selection"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import OptionSelectDialog from '@/components/common/OptionSelectDialog'
import { debounce } from "debounce";
import { mapGetters } from 'vuex'
import PlayerCreate from "./playerCreate";
import PlayerCreateAutomatically from "./playerCreateAutomatically";
export default {
    components: { PlayerCreate, PlayerCreateAutomatically, /* RoleCreate, */ ConfirmDialog, OptionSelectDialog },
    props: {
        gameId: {
            type: Number,
            required: true
        },
        teams_count: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: 'admin/games/players',
            /* dialog: false, */
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: 'Equipo',
                    value: 'pivot.team'
                },
                {
                    text: this.$t('admin.users.username'),
                    value: 'username'
                },
                {
                    text: this.$t('admin.users.firstname'),
                    value: 'name'
                },
                {
                    text: this.$t('admin.users.lastname'),
                    value: 'lastname'
                },
                {
                    text: this.$t('admin.users.email'),
                    value: 'email'
                },
                {
                    text: this.$t('admin.users.enable'),
                    value: 'enable',
                    sortable: false
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: 'Se unió',
                    value: 'pivot.created_at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemSelected: null,
            dialog: false,
            dialogAuto: false,
            sortby: ['id'],
            options: {
                itemsPerPage: 5
            }
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },

    created() {
        this.fetchPlayers();
    },

    mounted() {

    },

    methods: {
        fetchPlayers: debounce(function (e) {
            this.fetchPlayersFinal(e);
        }, 500),

        async fetchPlayersFinal(data = this.options) {
            try {
                const result = await axios
                    .get(`${this.urlMain}/${this.gameId}`);

                this.initialize(result.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.players ?? [];

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        createPost() {
            this.$router.push('/app/admin/cards/create/' + this.gameId);
        },
        /* setRole($data) {
      this.itemsRoles = $data;
  }, */
        createPlayer() {
            this.dialog = true;
        },
        async deletePlayers(item = null) {
            const text = item ? "¿Desea eliminar permanentemente el usuario o solamente quitarlo del juego?"
                : "¿Desea eliminar permanentemente todos los usuarios del juego o solamente quitarlos del juego?";

            const value = await this.$refs.selection.open(
                this.$t('common.confirmTitle'),
                text,
                {
                    buttons: [
                        {
                            text: "Cancelar",
                            value: "0"
                        },
                        {
                            text: "Quitar del juego",
                            value: "1"
                        },
                        {
                            text: "Eliminar Permanentemente",
                            value: "2"
                        }
                    ]
                }
            )

            if (value !== "0") this.deleteItemConfirmed(item, value === "2");
            // this.deleteItemConfirmed(item)
        },
        editItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },
        async deleteItemConfirmed(item, deleteUser = false) {
            let urlAppend = `/${this.gameId}`;
            urlAppend += item?.id ? `/${item.id}` : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend, {
                        data: {
                            deleteUser: deleteUser
                        }
                    });

                if (result.status === 200) {
                    this.fetchPlayers();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    "¿Desea eliminar el jugador del juego?"
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        filterTableItems(value, search, item) {
            // console.log("filterTableItems", value);
            let filter = false;
            if (value != null &&
                search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    if (value == search) {
                        filter = true;
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                }
            }

            return filter;
        },
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=' + image : '';
        }
    },
    computed: {

    }
}
</script>
